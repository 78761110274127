<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--      <dark-Toggler class="d-none d-lg-block" />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <search-bar />-->
      <!--      <dark-Toggler class="d-none d-lg-block" />-->

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status">{{ userData.email }}</span>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({ name: 'users-update', params: {id: userData.id} })"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Мой профиль</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="authOnWeb"
        >
          <span>Войти на RR/IS</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Выйти</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import _ from 'lodash'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { getUserData } from '@/auth/utils'
import router from '@/router'
// eslint-disable-next-line no-unused-vars
import axiosIns from '@/libs/axios'
import jwtConfig from '@/auth/jwt/jwtConfig'
// import SearchBar from './SearchBar.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    // SearchBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {},
      citiesList: [],
    }
  },
  mounted() {
    axiosIns({
      method: 'GET',
      url: '/auth/me',
    }).then(response => {
      Vue.ls.set('userData', JSON.stringify(response.data.data))
      this.userData = response.data.data
    })
  },
  methods: {
    getUserData,
    async authOnWeb() {
      _.each(['www.rasprodaga.ru', 'www.infoskidka.ru'], domain => {
        const element = domain
        const token = localStorage.getItem(jwtConfig.storageTokenKeyName)
        const img = document.createElement('img')
        img.src = `https://${element}/smartleads/share-session/?key=${token}`
        img.style.display = 'none'
        //
        img.onload = () => {
          this.sendToast({
            title: element,
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Авторизация прошла успешно!',
          })
          img.remove()
        }
        //
        img.onerror = () => {
          this.sendToast({
            title: element,
            icon: 'AlertCircleIcon',
            text: 'Что-то пошло не так.',
            variant: 'danger',
          })
          img.remove()
        }
      })
    },
    logout() {
      this.$jwtService.logout().then(() => {
        this.$jwtService.clearAuthData()
        _.each(['www.rasprodaga.ru', 'www.infoskidka.ru'], domain => {
          const element = domain
          const img = document.createElement('img')
          img.src = `https://${element}/smartleads/logout-session`
          img.style.display = 'none'

          img.onload = () => {
            img.remove()
          }
          //
          img.onerror = () => {
            img.remove()
          }
        })
        router.push({ name: 'login' })
        this.sendToast({
          title: 'Авторизация',
          icon: 'LogOutIcon',
          variant: 'success',
          text: 'Всего хорошего, ждем Вас снова!',
        })
      })
    },
  },
}
</script>
