export default [
  {
    title: 'Главная',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Врачи',
    route: 'doctors',
    icon: 'UsersIcon',
  },
  {
    title: 'Отзывы врачей',
    route: 'doctors-reviews',
    icon: 'CloudIcon',
  },
  {
    title: 'Специализации врачей',
    route: 'doctors-specialities',
    icon: 'UserIcon',
  },
  {
    title: 'Клиники',
    route: 'clinics',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Отзывы клиник',
    route: 'clinics-reviews',
    icon: 'CloudIcon',
  },
  {
    title: 'Сети клиник',
    route: 'clinic-networks',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Типы клиник',
    route: 'clinic-types',
    icon: 'HomeIcon',
  },
  {
    title: 'Мед. Журнал',
    route: 'med-journal',
    icon: 'BookIcon',
  },
  {
    title: 'Мед. Энциклопедия',
    route: 'med-encyclopedia',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Метро',
    route: 'metro',
    icon: 'GitBranchIcon',
  },
  {
    title: 'Города',
    route: 'cities',
    icon: 'UmbrellaIcon',
  },
  {
    title: 'Регионы',
    route: 'regions',
    icon: 'GlobeIcon',
  },
  {
    title: 'Пользователи',
    route: 'users',
    icon: 'UserIcon',
  },
]
